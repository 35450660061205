import { assign, createMachine } from 'xstate';
import { NavigationSections } from '../constants';
import { transferToRapid } from '../helpers/cookies/unified.helper';
import {
  DraftEvents,
  DraftMachineContext,
  DraftMachineEvents,
  DraftMachineGuards,
  DraftMachineStates,
  DraftStates,
} from './types/draftMachine.types';

export default createMachine<DraftMachineContext, DraftMachineEvents, DraftMachineStates>(
  {
    id: 'Draft',
    initial: DraftStates.Entry,
    context: {
      order: null,
      transitionedFrom: null,
      highestSupportedApplicationMilestone: null,
      hardDeclineError: null,
    },
    states: {
      // ephemeral state, used exclusively for immediate switching
      [DraftStates.Entry]: {
        always: [
          {
            target: DraftStates.PersonalInformation,
            cond: 'NoApplication',
            actions: assign({
              transitionedFrom: () => DraftStates.Entry,
            }),
          },
          {
            target: DraftStates.PersonalInformation,
            cond: 'NoAppStep',
            actions: assign({
              transitionedFrom: () => DraftStates.Entry,
            }),
          },
          {
            target: DraftStates.PersonalInformation,
            cond: 'AppStepPersonalInformation',
            actions: assign({
              transitionedFrom: () => DraftStates.Entry,
            }),
          },
          {
            target: DraftStates.ContactInformation,
            cond: 'AppStepContactData',
            actions: assign({
              transitionedFrom: () => DraftStates.Entry,
            }),
          },
          {
            target: DraftStates.IncomeSource,
            cond: 'AppStepIncomeSource',
            actions: assign({
              transitionedFrom: () => DraftStates.Entry,
            }),
          },
          {
            target: DraftStates.BankingInformation,
            cond: 'AppStepBankingInformation',
            actions: assign({
              transitionedFrom: () => DraftStates.Entry,
            }),
          },
          {
            target: DraftStates.DebitCardInformation,
            cond: 'AppStepDebitCardInformation',
            actions: assign({
              transitionedFrom: () => DraftStates.Entry,
            }),
          },
          {
            target: DraftStates.TransferToRapid,
            cond: 'AppStepReviewAndSubmitM2',
            actions: assign({
              transitionedFrom: () => DraftStates.Entry,
            }),
          },
          {
            target: DraftStates.ReviewAndSubmit,
            cond: 'AppStepReviewAndSubmitM3',
            actions: assign({
              transitionedFrom: () => DraftStates.Entry,
            }),
          },
          {
            target: DraftStates.AmendAndResubmit,
            cond: 'AppStepAmendAndResubmit',
            actions: assign({
              transitionedFrom: () => DraftStates.Entry,
            }),
          },
        ],
      },
      [DraftStates.PersonalInformation]: {
        on: {
          [DraftEvents.NEXT]: [
            {
              target: DraftStates.ContactInformation,
              cond: 'TransitionedFromAppSteps',
              actions: assign({
                transitionedFrom: () => DraftStates.PersonalInformation,
              }),
            },
            {
              target: DraftStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => DraftStates.PersonalInformation,
              }),
            },
          ],
          [DraftEvents.BACK]: [
            {
              target: DraftStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => DraftStates.ContactInformation,
              }),
            },
          ],
        },
        meta: {
          navigationSection: NavigationSections.CHECK_ELIGIBILITY,
        },
      },
      [DraftStates.ContactInformation]: {
        on: {
          [DraftEvents.NEXT]: [
            {
              target: DraftStates.BillingAddress,
              cond: 'TransitionedFromAppSteps',
              actions: assign({
                transitionedFrom: () => DraftStates.ContactInformation,
              }),
            },
            {
              target: DraftStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => DraftStates.ContactInformation,
              }),
            },
          ],
          [DraftEvents.BACK]: [
            {
              target: [DraftStates.PersonalInformation],
              cond: 'TransitionedFromAppSteps',
              actions: assign({
                transitionedFrom: () => DraftStates.ContactInformation,
              }),
            },
            {
              target: DraftStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => DraftStates.ContactInformation,
              }),
            },
          ],
        },
        meta: {
          navigationSection: NavigationSections.CHECK_ELIGIBILITY,
          canGoBackViaHeader: true,
        },
      },
      [DraftStates.BillingAddress]: {
        // comes after basic info continue, but doesnt have an associated appStep
        on: {
          [DraftEvents.NEXT]: [
            {
              target: DraftStates.IncomeSource,
              cond: 'TransitionedFromAppSteps',
              actions: assign({
                order: (_context, event) => event.data.order,
                transitionedFrom: () => DraftStates.BillingAddress,
              }),
            },
            {
              target: DraftStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => DraftStates.BillingAddress,
              }),
            },
          ],
          [DraftEvents.BACK]: [
            {
              target: [DraftStates.ContactInformation],
              cond: 'TransitionedFromAppSteps',
              actions: assign({
                transitionedFrom: () => DraftStates.ContactInformation,
              }),
            },
            {
              target: DraftStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => DraftStates.ContactInformation,
              }),
            },
          ],
          [DraftEvents.VIEW_CUSTOMER_ID_PROGRAM]: {
            target: DraftStates.CustomerIdProgram,
          },
          [DraftEvents.VIEW_TERMS_AND_CONDITIONS]: {
            target: DraftStates.TermsAndConditions,
          },
          [DraftEvents.VIEW_PREQUALIFICATION_NOTICE]: {
            target: DraftStates.PrequalificationNotice,
          },
          [DraftEvents.VIEW_AUTHORIZED_USER_TERMS]: {
            target: DraftStates.AuthorizedUserTerms,
          },
        },
        meta: {
          canGoBackViaHeader: true,
        },
      },
      [DraftStates.IncomeSource]: {
        on: {
          [DraftEvents.NEXT]: [
            {
              target: DraftStates.BankingInformation, // is bav turned off for any long form stores?
              cond: 'TransitionedFromAppSteps',
              actions: assign({
                transitionedFrom: () => DraftStates.IncomeSource,
              }),
            },
            {
              target: DraftStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => DraftStates.IncomeSource,
              }),
            },
          ],
          [DraftEvents.BACK]: [
            {
              target: DraftStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => DraftStates.ContactInformation,
              }),
            },
          ],
        },
        meta: {
          navigationSection: NavigationSections.PAYMENT_DETAILS,
        },
      },
      [DraftStates.BankingInformation]: {
        on: {
          [DraftEvents.NEXT]: [
            {
              target: DraftStates.DebitCardInformation,
              cond: 'TransitionedFromAppSteps',
              actions: assign({
                transitionedFrom: () => DraftStates.BankingInformation,
              }),
            },
            {
              target: DraftStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => DraftStates.BankingInformation,
              }),
            },
          ],
          [DraftEvents.BACK]: [
            {
              target: DraftStates.IncomeSource,
              cond: 'TransitionedFromAppSteps',
              actions: assign({
                transitionedFrom: () => DraftStates.BankingInformation,
              }),
            },
            {
              target: DraftStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => DraftStates.BankingInformation,
              }),
            },
          ],
        },
        meta: {
          navigationSection: NavigationSections.PAYMENT_DETAILS,
          canGoBackViaHeader: true,
        },
      },
      [DraftStates.DebitCardInformation]: {
        on: {
          [DraftEvents.NEXT]: [
            {
              target: DraftStates.TransferToRapid,
              cond: (context, _event) =>
                (!context.highestSupportedApplicationMilestone || context.highestSupportedApplicationMilestone < 3) &&
                context.transitionedFrom !== DraftStates.ReviewAndSubmit,
            },
            {
              target: DraftStates.ReviewAndSubmit,
              cond: (context, _event) =>
                !!context.highestSupportedApplicationMilestone &&
                context.highestSupportedApplicationMilestone >= 3 &&
                context.transitionedFrom !== DraftStates.ReviewAndSubmit,
            },
            {
              target: DraftStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => DraftStates.DebitCardInformation,
              }),
            },
          ],
          [DraftEvents.BACK]: [
            {
              target: DraftStates.BankingInformation,
              cond: 'TransitionedFromAppSteps',
              actions: assign({
                transitionedFrom: () => DraftStates.DebitCardInformation,
              }),
            },
            {
              target: DraftStates.ReviewAndSubmit,
              cond: 'TransitionedFromReviewAndSubmit',
              actions: assign({
                transitionedFrom: () => DraftStates.DebitCardInformation,
              }),
            },
          ],
        },
        meta: {
          navigationSection: NavigationSections.PAYMENT_DETAILS,
          canGoBackViaHeader: true,
        },
      },
      [DraftStates.ReviewAndSubmit]: {
        on: {
          [DraftEvents.NEXT]: [
            {
              target: DraftStates.ContactInformation,
              cond: (_context, event) => {
                return event.data.target === DraftStates.ContactInformation;
              },
              actions: assign({
                transitionedFrom: () => DraftStates.ReviewAndSubmit,
              }),
            },
            {
              target: DraftStates.PersonalInformation,
              cond: (_context, event) => {
                return event.data.target === DraftStates.PersonalInformation;
              },
              actions: assign({
                transitionedFrom: () => DraftStates.ReviewAndSubmit,
              }),
            },
            {
              target: DraftStates.BillingAddress,
              cond: (_context, event) => event.data.target === DraftStates.BillingAddress,
              actions: assign({
                transitionedFrom: () => DraftStates.ReviewAndSubmit,
              }),
            },
            {
              target: DraftStates.IncomeSource,
              cond: (_context, event) => event.data.target === DraftStates.IncomeSource,
              actions: assign({
                transitionedFrom: () => DraftStates.ReviewAndSubmit,
              }),
            },
            {
              target: DraftStates.BankingInformation,
              cond: (_context, event) => event.data.target === DraftStates.BankingInformation,
              actions: assign({
                transitionedFrom: () => DraftStates.ReviewAndSubmit,
              }),
            },
            {
              target: DraftStates.DebitCardInformation,
              cond: (_context, event) => event.data.target === DraftStates.DebitCardInformation,
              actions: assign({
                transitionedFrom: () => DraftStates.ReviewAndSubmit,
              }),
            },
            {
              target: DraftStates.Approval,
              cond: (_context, event) => event.data.target === DraftStates.Approval,
              actions: assign({
                transitionedFrom: () => DraftStates.ReviewAndSubmit,
              }),
            },
            {
              target: DraftStates.Declined,
              actions: assign({
                transitionedFrom: () => DraftStates.ReviewAndSubmit,
                hardDeclineError: (_context, event) => event.data.hardDeclineError,
              }),
            },
          ],
          [DraftEvents.BACK]: {
            target: DraftStates.DebitCardInformation,
            actions: assign({
              // TODO: [M3] Handle how far you can go 'back' in the flow from ReviewAndSubmit. This will create a loop between debit card and review pages
              transitionedFrom: () => DraftStates.ReviewAndSubmit,
            }),
          },
        },
        // meta: {
        //   navigationSection: NavigationSections.REVIEW_AND_PAY,
        //   canGoBackViaHeader: true,
        // },
      },
      [DraftStates.AmendAndResubmit]: {
        // TODO: ignore until M3
        on: {
          [DraftEvents.NEXT]: {
            target: DraftStates.ReviewAndSubmit,
          },
        },
        meta: {
          navigationSection: NavigationSections.REVIEW_AND_PAY,
          canGoBackViaHeader: true,
        },
      },
      [DraftStates.TransferToRapid]: {
        entry: 'transferToRapid',
      },

      // info pages
      [DraftStates.CustomerIdProgram]: {
        on: {
          [DraftEvents.NEXT]: { target: DraftStates.BillingAddress },
        },
      },
      [DraftStates.TermsAndConditions]: {
        on: {
          [DraftEvents.NEXT]: {
            target: DraftStates.BillingAddress,
          },
        },
      },
      [DraftStates.PrequalificationNotice]: {
        on: {
          [DraftEvents.NEXT]: {
            target: DraftStates.BillingAddress,
          },
        },
      },
      [DraftStates.AuthorizedUserTerms]: {
        on: {
          [DraftEvents.BACK]: {
            target: DraftStates.BillingAddress,
          },
        },
      },
      [DraftStates.Declined]: {
        // TODO: [M3] What events can happen from the decline screen that need to be handled here?
      },
      [DraftStates.Approval]: {
        // TODO: [M3] Authorize card on submission
      },
    },
    schema: {
      context: {} as DraftMachineContext,
      events: {} as DraftMachineEvents,
      guards: {} as DraftMachineGuards,
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
  },
  {
    actions: {
      transferToRapid: () => {
        transferToRapid();
      },
      // strictly for debugging, do not remove and make sure you clean up references in MRs
      log: (context, event) => {
        console.log('Draft Application');
        console.log('context', context);
        console.log('event', event);
      },
    },
    guards: {
      NoApplication: (context) => {
        return !context.order?.application?.lease?.id;
      },
      NoAppStep: (context) => {
        return !context.order?.application?.lease?.appStep;
      },
      AppStepPersonalInformation: (context) => {
        return context.order?.application?.lease?.appStep === 'personalInformation';
      },
      AppStepContactData: (context) => {
        return context.order?.application?.lease?.appStep === 'contactData';
      },
      AppStepIncomeSource: (context) => {
        return context.order?.application?.lease?.appStep === 'incomeSource';
      },
      AppStepBankingInformation: (context) => {
        return context.order?.application?.lease?.appStep === 'bankingInformation';
      },
      AppStepDebitCardInformation: (context) => {
        return context.order?.application?.lease?.appStep === 'debitCardInformation';
      },
      AppStepReviewAndSubmitM2: (context) => {
        return (
          (!context.highestSupportedApplicationMilestone || context.highestSupportedApplicationMilestone < 3) &&
          context.order?.application?.lease?.appStep === 'reviewAndSubmit'
        );
      },
      AppStepReviewAndSubmitM3: (context) => {
        return (
          !!context.highestSupportedApplicationMilestone &&
          context.highestSupportedApplicationMilestone >= 3 &&
          context.order?.application?.lease?.appStep === 'reviewAndSubmit'
        );
      },
      AppStepAmendAndResubmit: (context) => {
        return context.order?.application?.lease?.appStep === 'amendAndResubmit';
      },
      TransitionedFromReviewAndSubmit: (context) => {
        return context.transitionedFrom === DraftStates.ReviewAndSubmit;
      },
      TransitionedFromAppSteps: (context) => {
        return context.transitionedFrom !== DraftStates.ReviewAndSubmit;
      },
    },
  },
);
