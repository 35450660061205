import React, { useEffect } from 'react';
import { useStoreState } from '../../hooks/easyPeasyHooks';
import { ExitCodes, ModalMessage, ModalMessageFailure, modalResolver } from '../../helpers/modal/modal.helper';
import { usePage, useTrack } from '../../hooks/segment';
import Message from '../../components/message/Message';
import { UiType, getTargetUi } from '../../helpers/cookies/unified.helper';
import { ChannelTypes } from '../../gql/graphql';

type DeclinedProps = {
  heading?: string;
  body?: string | JSX.Element;
  additionalBody?: string;
  trackDetails?: { title: string; details?: any };
};

// Now that an app can be declined after prequal, the wording of the message differs depending on
// when the decline happened.  Presently only bank account validation
// TODO: I don't really love checking/handling specific decline reasons, is there a better way to determine
//       if the decline occurred before/after prequal?
const getDefaultHeading = (declineReason: string | undefined) => {
  switch (declineReason) {
    case 'bankAccount':
    case 'debitCardError': // Does not work as decline reason is actually empty in this case...but why
      return "We aren't able to approve you at this time";
    default:
      return "We aren't able to prequalify you at this time";
  }
};

const Declined: React.FC<DeclinedProps> = (props) => {
  const domains = useStoreState((state) => state.order.order?.dealer.domains!);
  const { orderId } = useStoreState((state) => state.order);
  const isVirtualCard = useStoreState((state) => state.order.order?.dealer.isVirtualCard);
  const page = usePage();
  const track = useTrack();
  const channelType = useStoreState((state) => state.order.order?.dealer.channelType);
  const leaseDeclineReason = useStoreState((state) => state.order.order?.application?.lease?.declineReason);
  const uiType = getTargetUi(); // TODO: remove and move to SM context

  useEffect(() => {
    const sendDeclinedEvent = () => {
      const payload: ModalMessage = {
        wcfSuccess: false,
        closeModal: false,
        payload: {
          orderId,
          reasonCode: ExitCodes.hardDecline,
          reason: 'hardDecline',
        } as ModalMessageFailure,
      };

      modalResolver(payload, domains);
    };
    page('Declined');
    sendDeclinedEvent();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseModal = () => {
    const payload: ModalMessage = {
      wcfSuccess: false,
      closeModal: true,
      payload: {
        reason: 'modal closed',
        reasonCode: ExitCodes.generalCloseModal,
        orderId: orderId,
      } as ModalMessageFailure,
    };
    modalResolver(payload, domains);
  };

  if (!!props.trackDetails) {
    track(props.trackDetails.title, props.trackDetails.details);
  }

  // Dont show close button for virtual card, B&M or loans because it will do nothing
  const shouldShowButton = !isVirtualCard && uiType === UiType.Unified && channelType !== ChannelTypes.BrickAndMortar;
  return (
    <Message
      heading={props.heading || getDefaultHeading(leaseDeclineReason ?? undefined)}
      body={
        props.body ||
        "You'll receive a communication with more information on why your application didn't fit our criteria soon. You will be able to apply again in 30 days."
      }
      additionalBody={props.additionalBody}
      buttonText={'Exit'}
      buttonClick={handleCloseModal}
      shouldShowButton={shouldShowButton}
    />
  );
};

export default Declined;
