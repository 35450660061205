import React, { useState, useEffect } from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useStoreActions, useStoreState } from '../../hooks/easyPeasyHooks';
import { validateDate, formatToYYYYMMDD, parseDateToSeparateFields } from '../../helpers/date/date.helper';
import { BasicInfoContinuedModel } from '../../stores/applicationForm.store';
import { useTrack, usePage, useIdentify } from '../../hooks/segment';
import { CustomerLookupEvents } from '../../state-machine/types/customerLookupMachine.types';
import { FormNavButtonWrapper } from '../../components/formElements/FormElements';
import TextField from '../../components/inputs/TextField';
import Button from '../../components/button/Button';
import FormWrapper from '../../components/formWrapper/FormWrapper';

interface BasicInfoContinuedFormModel {
  dob: string;
  taxpayerIdentificationNumber: string;
  monthlyIncome: string;
}

type BasicInfoContinuedProps = {
  messages: {
    submitButton: string;
  };
  onNext: any;
};

const BasicInfoContinued: React.FC<BasicInfoContinuedProps> = ({ onNext, messages }) => {
  const { setBasicInfoContinued, customerSearchByCellPhone, setApplyErrors, setErrorGeneral } = useStoreActions(
    (actions) => actions.applicationForm,
  );
  const {
    eligibilityDetails,
    wasEligibilityUpdated,
    basicInfo,
    basicInfoContinued,
    customers,
    cellPhone,
    customerSearchErrors,
  } = useStoreState((state) => state.applicationForm);
  const identify = useIdentify();
  const track = useTrack();
  const page = usePage();
  const { orderId } = useStoreState((state) => state.order);

  const [isLoading, setIsLoading] = useState(false);
  const [isDataSaved, setDataSaved] = useState(false);

  // const history = useHistory();

  // birthDate is one field in our store. This logic will deconstruct date
  // into appropriate text inputs
  const { day, month, year } = parseDateToSeparateFields(basicInfoContinued?.birthDate);
  const formattedFieldData: BasicInfoContinuedFormModel = {
    monthlyIncome: basicInfoContinued?.monthlyIncome || '',
    taxpayerIdentificationNumber: basicInfoContinued?.taxpayerIdentificationNumber || '',
    dob: basicInfoContinued?.birthDate ? `${month}/${day}/${year}` : '',
  };

  useEffect(() => {
    page('Basic Info Continued');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (customers && customers[0]?.id) {
      identify({
        customerId: customers[0].id,
        emailAddress: customers[0].emailAddress,
        cellPhone: customers[0].cellPhone,
        firstName: customers[0].firstName,
        lastName: customers[0].lastName,
      });
    } // otherwise, continue to use the anonymous identify
  }, [customers]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      isDataSaved &&
      wasEligibilityUpdated &&
      (eligibilityDetails?.isEligible || customerSearchErrors.some((error) => error.__typename === 'NotFound'))
    ) {
      track('Passed eligibility');
      onNext({ type: CustomerLookupEvents.NEXT });
      // history.push('/flow/billing-address');
    } else if (wasEligibilityUpdated && isDataSaved) {
      onNext({ type: CustomerLookupEvents.NEXT });
      // history.push('/flow/billing-address');
    }
  }, [wasEligibilityUpdated, eligibilityDetails, isDataSaved]); // eslint-disable-line react-hooks/exhaustive-deps

  const IncomeTooltipContent = (
    <p>
      You don't have to include alimony, child support or separate maintenance income if you don't choose to have it
      considered in our decision to offer you financing.
    </p>
  );

  // For birthDate inputs, we have yup field validation as well as handling any
  // date logic upon submission.
  let validateBirthdateError: string | undefined;
  const validateBirthdate = (values: any) => {
    const errors: any = {};
    validateBirthdateError = undefined;

    const [dobMonth, dobDay, dobYear] = values.dob.split('/');

    // if year is not 4 digits long, will let yup validation handle
    if (!dobYear || dobYear?.length !== 4) return;
    validateBirthdateError = validateDate({
      year: dobYear,
      month: dobMonth,
      day: dobDay,
      maxYearsAgo: 125,
      minYearsAgo: 18,
      errorMsg: 'You must be at least 18 years of age to apply',
    });
    if (validateBirthdateError) {
      // adding this highlights the text inputs with red to signal issue
      errors.dob = validateBirthdateError;
    }
    return errors;
  };

  const handleSubmit = (e: BasicInfoContinuedFormModel) => {
    setIsLoading(true);
    if (validateBirthdateError) {
      setIsLoading(false);
      return;
    }

    const [dobMonth, dobDay, dobYear] = e.dob.split('/');
    // this track needs to be below validateBirthdayError
    track('ID & Income Entered', { monthlyIncome: e.monthlyIncome, birthYear: dobYear });
    // This combines separate birthDate inputs into one field for store
    const modifiedFormData: BasicInfoContinuedModel = {
      birthDate: formatToYYYYMMDD({ day: dobDay, month: dobMonth, year: dobYear }),
      monthlyIncome: e.monthlyIncome.replace('$', '').replace(',', ''),
      taxpayerIdentificationNumber: e.taxpayerIdentificationNumber,
    };

    // Reset errors
    setApplyErrors([]);
    setErrorGeneral(null);

    setBasicInfoContinued({ ...basicInfoContinued, ...modifiedFormData });
    setDataSaved(true);

    //when no customers found we want to search for them with taxID and birthdate
    if (customers?.length === 0) {
      customerSearchByCellPhone({
        orderId: orderId!,
        taxId: modifiedFormData.taxpayerIdentificationNumber,
        birthDate: modifiedFormData.birthDate,
        cellPhone: cellPhone,
        analytics: {
          campaignMedium: new URLSearchParams(document.location.search.substring(1)).get('cm') || undefined,
          campaignSource: new URLSearchParams(document.location.search.substring(1)).get('cs') || undefined,
        },
      });
    }
  };

  const ssnValidationSchema = Yup.object().shape({
    taxpayerIdentificationNumber: Yup.string()
      .required(' ')
      .min(9, 'Your SSN or ITIN must be 9 digits')
      .matches(/([0-9]){9}/, ' '),
  });

  const monthlyIncomeDoBValidationSchema = Yup.object().shape({
    monthlyIncome: Yup.string().min(5, 'Your monthly income must be more than $1000').required(' '),
    dob: Yup.string()
      .required(' ')
      .matches(/\d{1,2}\/\d{1,2}\/\d{4}/, ' '),
  });

  let validationSchema = monthlyIncomeDoBValidationSchema;

  if (customers?.length === 0) {
    validationSchema = monthlyIncomeDoBValidationSchema.concat(ssnValidationSchema);
  }

  return (
    <FormWrapper>
      <h2 className="formHeader">
        {customers?.length === 0 ? `Nice to meet you, ${basicInfo.firstName}!` : 'Let’s verify some info'}
      </h2>
      <h6>We need some more information about you to verify your identity.</h6>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ ...formattedFieldData }}
        validationSchema={validationSchema}
        validate={validateBirthdate}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {() => (
          <Form>
            <Field
              name="dob"
              id="dob"
              label="Date of Birth"
              component={TextField}
              type="custDate"
              autoFocus
              placeholder="MM/DD/YYYY"
              inputMode="numeric"
              cleaveOptions={{
                date: true,
                datePattern: ['m', 'd', 'Y'],
              }}
            />
            {customers?.length === 0 && (
              <Field
                name="taxpayerIdentificationNumber"
                id="taxpayerIdentificationNumber"
                label="SSN/ITIN"
                component={TextField}
                mask={true}
                type="tel"
                inputMode="numeric"
                useRawValue={true}
                cleaveOptions={{
                  blocks: [3, 2, 4],
                  delimiter: '-',
                  prefix: '',
                  noImmediatePrefix: true,
                }}
                fsExclude
              />
            )}
            <Field
              name="monthlyIncome"
              id="monthlyIncome"
              label="Estimated Monthly Income"
              component={TextField}
              useRawValue={true}
              inputMode="numeric"
              cleaveOptions={{
                prefix: '$',
                numeral: true,
                delimiter: ',',
                // rawValueTrimPrefix: true,
                noImmediatePrefix: true,
                numeralPositiveOnly: true,
              }}
              tooltipContent={IncomeTooltipContent}
            />

            {/* <FormFinePrint>
              By clicking the 'SUBMIT APPLICATION' button, I authorize Koalafi to obtain a credit report, communicate
              with me and share my information with {storeName && storeName}.
            </FormFinePrint> */}
            <FormNavButtonWrapper style={{ marginTop: '0px' }}>
              <Button type="submit" dataLoading={isLoading}>
                {messages.submitButton}
              </Button>
              {/* <Link
                // to="/flow/basic-info"
                onClick={() => {
                  track('Go Back selected', { goingBackTo: '/flow/basic-info' });
                  onNext({ type: CustomerLookupEvents.BACK });
                }}
                linkText={'go back'}
              /> */}
            </FormNavButtonWrapper>
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};

export default BasicInfoContinued;
